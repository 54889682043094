import i18n from "../../../locales/i18n"
import { I18nextProvider, useTranslation } from "react-i18next"
import { AppProvider as PolarisAppProvider } from "@shopify/polaris"
import "@shopify/polaris/build/esm/styles.css"
import poralis_ja_translations from "@shopify/polaris/locales/ja.json"
import poralis_en_translations from "@shopify/polaris/locales/en.json"
import React, { FC, useEffect } from "react"
import { UrqlProvider } from "../"

type Props = {
  lang: "ja" | "en"
}

const AppProvider: FC<Props> = ({ children, lang }) => {
  const poralisI18n =
    lang === "ja" ? poralis_ja_translations : poralis_en_translations

  useEffect(() => {
    i18n.changeLanguage(lang)
  }, [lang])

  return (
    <I18nextProvider i18n={i18n}>
      <PolarisAppProvider i18n={poralisI18n}>
        <UrqlProvider>{children}</UrqlProvider>
      </PolarisAppProvider>
    </I18nextProvider>
  )
}

export { AppProvider }
